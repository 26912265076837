import { API_BASE_URL } from '@/constants';

export default {
    async initPageText({ commit, getters }, pageKey) {
        console.log('LANG GETTERS FROM INIT PAGE ACTION', getters['lang/selectedLang']);
        console.log('LANG GETTERS FROM INIT PAGE ACTION',API_BASE_URL + 'text/page/' + pageKey);

        let texts;

        const response = await axios.get(API_BASE_URL + 'text/page/' + pageKey);



        commit('initPageText', { texts: response.data.resource.data })
    }
};
