import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

Vue.use(VueRouter);

/**
 * Guard the route from unauthorized users.
 *
 * @param  {Route}    to   The route we want to access.
 * @param  {Route}    from The route from which we are coming from.
 * @param  {Function} next Callback for passing a route to be called next.
 * @return {void}
 */
function guardRoute(to, from, next) {
  // work-around to get to the Vuex store (as of Vue 2.0)
  const auth = router.app.$options.store.state.auth;

  if (!auth.isLoggedIn) {
    next({ path: '/login', query: { redirect: to.fullPath } });
  } else {
    next();
  }
}

/**
 * The Router instance containing all the routes for the application.
 */
const router = new VueRouter({
  base: '/',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: 'history', // <-- uncomment to turn on history mode (preferred)
  routes: routes.map((route) => ({
    name: route.name,
    path: route.path,
    component: route.component,
      /**
       * Run this callback before resolve the routing
       */
    beforeEnter: (to, from, next) => {
      // Setup some per-page stuff.
      document.title = route.title;
      /**
       * Dispatch actions for update state via routing and routes data bind
       */
      router.app.$options.store.dispatch('updateTitle', route.title);
      router.app.$options.store.dispatch('updateLayout', route.layout);
      router.app.$options.store.dispatch('updateFooter', route.footer);
      router.app.$options.store.dispatch('updateHeader', route.header);
      router.app.$options.store.dispatch('initPageText', route.pageKey);

      // Auth navigation guard.
      if (!route.isPublic) return guardRoute(to, from, next);

      next();
    },
  })),
});

export default router;
