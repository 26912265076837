export default {
  // apiInitDispatch: {
  //   root: true,
  //   handler({ commit, dispatch }) {
  //   },
  // },
  //
  // updateTitle: {
  //   root: true,
  //   handler({ commit }, title) {
  //     commit('updateTitle', title);
  //   },
  // },
  updateTitle({ commit }, title) {
    commit('updateTitle', title);
  },

  apiInitDispatch({ commit, dispatch }) {
  },

  updateLayout({ commit }, layout) {
    commit('updateLayout', layout);
  },

  updateFooter({ commit }, footer) {
    commit('updateFooter', footer);
  },

  updateHeader({ commit }, header) {
    commit('updateHeader', header);
  },

  updateTexts({ commit, dispatch }, pageKey) {
    dispatch('')
    commit('updateTexts', pageKey);
  },

  isDropMenuActive({ commit }, active) {
    commit('updateDropMenu', { isActive: active });
  },

  scrollToTop() {
    window.scrollTo(0, 0);
  },
};
