export default {
    data     : {
        items           : {},
        pies            : {},
        totalPrice      : 0,
        totalAmount     : 0,
        totalItemsAmount: 0,
        totalItemsPrice : 0,
        totalPiesPrice  : 0,
        totalPiesAmount : 0,
    },
    isDropped: false,
};
