import state from "./state";

import defaults from './state'


export default {
    updateCartDropped(state) {
        state.isDropped = !state.isDropped;
    },

    clearCart(state) {
        state.data.pies = {};
        state.data.items = {};
        state.data.totalAmount = 0
        state.data.totalItemsAmount = 0
        state.data.totalItemsPrice = 0
        state.data.totalPiesAmount = 0
        state.data.totalPiesPrice = 0
        state.data.totalPrice = 0
    },

    addCartItem(state, {id, amount, title, price, title_ru, title_ua, title_en, title_pl}) {
        let haveDublicate = false;
        const obj = state.data.items;

        //Добавление элемента
        Object.entries(obj).map(([key, value]) => {
            if (key === id) {
                console.log(value.title + ': ' + title);
                obj[key].amount = amount;
                haveDublicate = true;
            }
        });

        //Если не дублируется
        if (!haveDublicate) {
            obj[id] = {
                amount: amount,
                title: title,
                title_ru: title_ru,
                title_ua: title_ua,
                title_en: title_en,
                title_pl: title_pl,
                price: price
            };
        }

        //Удалает элемент если меньше ноля
        Object.entries(obj).map(([key, value]) => {
            let splitAmount = String(value.amount).split('');

            if (+splitAmount[0] === 0) {
                splitAmount.splice(0, 1);
                let result = splitAmount.join('');
                obj[key].amount = +result;
            }

            if (value.amount <= 0) {
                delete obj[key];
                if (Object.keys(obj).length === 0) {
                    this.state.ordering.data.allBool = false;
                    this.state.ordering.data.chooseAllBool = false;
                }
            }
        });

        // Object.entries(obj).map(([key, value]) => {});

        state.data.totalPrice = 0;
        state.data.totalItemsPrice = 0;
        state.data.totalAmount = 0;
        state.data.totalItemsAmount = 0;


        Object.entries(obj).map(([key, value]) => {
            state.data.totalPrice += +value.price * value.amount;
            state.data.totalItemsPrice += +value.price * value.amount;
            state.data.totalItemsAmount += +value.amount;
            state.data.totalAmount += +value.amount;
        });
    },

    addCartPie(state, {id, amount, title, title_ru, title_ua, title_en, title_pl, price}) {
        let haveDublicate = false;
        const obj = state.data.pies;

        //Добавление элемента
        Object.entries(obj).map(([key, value]) => {
            if (key === id) {
                console.log(value.title + ': ' + title);
                let amountItem = amount;
                obj[key].amount = amountItem;
                haveDublicate = true;
            }
        });

        //Если не дублируется
        if (!haveDublicate) {
            obj[id] = {
                amount: amount,
                title: title,
                price: price,
                title_ru: title_ru,
                title_ua: title_ua,
                title_en: title_en,
                title_pl: title_pl,
            };
        }

        //Удалает элемент если меньше ноля
        Object.entries(obj).map(([key, value]) => {
            let splitAmount = String(value.amount).split('');

            if (+splitAmount[0] === 0) {
                splitAmount.splice(0, 1);
                let result = splitAmount.join('');
                obj[key].amount = +result;
            }

            if (value.amount <= 0) {
                delete obj[key];
                if (Object.keys(obj).length === 0) {
                    this.state.ordering.data.allBool = false;
                    this.state.ordering.data.chooseAllBool = false;
                }
            }
        });

        // Object.entries(obj).map(([key, value]) => {});

        state.data.totalPrice = 0;
        state.data.totalPiesPrice = 0;
        state.data.totalAmount = 0;
        state.data.totalPiesAmount = 0;


        Object.entries(obj).map(([key, value]) => {
            state.data.totalPrice += +value.price * value.amount;
            state.data.totalPiesPrice += +value.price * value.amount;
            state.data.totalAmount += +value.amount;
            state.data.totalPiesAmount += +value.amount;

        });
    },
}
