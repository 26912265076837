<template>
  <div class="AppInput">
    <div
      class="AppInput__error"
      :class="{ valid: valid, error: hasError }"
    ></div>
    <input
      class="AppInput__input"
      :value="valueInput"
      :id="nameInput"
      :type="type"
      :name="nameInput"
      :disabled="disabled"
      v-model="valueInput"
      :placeholder="placeholder"
      @input="pushvModel(nameInput, valueInput)"
      @blur="$emit('blur')"
      @focus="$emit('focus')"
    />
    <label class="AppInput__label" :for="nameInput">{{ placeholder }} </label>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('ordering');

export default {
  name: 'AppInput',
  components: {},
  props: {
    type: String,
    placeholder: String,
    nameInput: String,
    valid: Boolean,
    hasError: Boolean,
    disabled: Boolean,
  },
  data() {
    return { valueInput: '' };
  },
  computed: {},

  mounted() {
    this.valueInput = this.getvData(this.nameInput)
  },

  methods: {
    ...mapActions(['changeData']),
    ...mapGetters(['getData']),
    pushvModel(name, value) {
      this.changeData({ key: name, value:value });
    },
    getvData(state) {
      return this.getData()[state];
    }
  },
};
</script>

<style lang="scss">
.AppInput {
  position: relative;
  width: 100%;

  &__input {
    position: relative;

    @include text($h16, 300, $grey, $f-montserrat);
    // @include flex(center, center, row, nowrap);
    background: transparent;
    border: 1px solid $grey;
    padding: 6px 5px 5px 7px;
    width: 100%;
    outline: none;
    @include transition(color);
    z-index: 3;

    &:focus {
      color: $light-yellow;

      &::placeholder {
        opacity: 1;
      }
    }

    &:focus + label {
      @include text($h10, 300, $yellow, $f-montserrat);
      top: -25px;
      padding-left: 0;

      @include media-d-m($screen-netbook) {
        top: -20px;
      }
    }

    &:not(:placeholder-shown) + label {
      @include text($h10, 300, $yellow, $f-montserrat);
      top: -25px;
      padding-left: 0;

      @include media-d-m($screen-netbook) {
        top: -20px;
      }
    }

    &::placeholder {
      opacity: 0;
      @include transition(opacity);
    }

    &:disabled {
      opacity: 0.3;
      & + label {
        opacity: 0.3;
      }
    }
  }

  &__label {
    cursor: pointer;
    @include text($h16, 300, $grey, $f-montserrat);
    position: absolute;
    top: 0;
    left: 0px;
    padding: 6px 5px 5px 7px;
    width: 100%;
    @include transition(color, top, font-size, padding-left);
  }

  &__error {
    position: relative;
    z-index: 1;
    height: 30px;
    margin-bottom: -30px;
    margin-right: 10px;

    &.valid {
      background: url('../../../assets/images/input/yes.png') right center no-repeat;
    }

    &.error {
      background: url('../../../assets/images/input/no.png') right center no-repeat;
    }
  }
}
</style>
