<template>
  <div class="AppHeader" v-scroll="updateHeight">
    <div class="AppHeader__inner container">
      <ul class="AppHeader__items">
        <li class="AppHeader__item">
          <router-link to="/">
            <img
                src="@/assets/images/logo__yellow.png"
                alt=""
                class="AppHeader__logo logo"
                @click="clickLogo"
            /></router-link>
        </li>
        <li class="AppHeader__item">
          <a target="blank" href="https://goo.gl/maps/f8FundFhXomE9mu3A"
          ><span class="AppHeader__contact">{{ date['text_' + selectedLang] }}</span></a
          >
        </li>
        <li class="AppHeader__item">
          <a class="AppHeader__tel" href="tel:+380503722730"
          >+38 (050) 372 27 30</a
          >
        </li>
        <li
            class="AppHeader__item AppHeader__carts carts"
            :class="{ active: this['header'].data.showCart }"
        >
          <div class="cart__drop" v-show="getCartIsDropped">
            <div class="cart__banner">
              <ul class="carts__items" @click="dropCart()">
                <li class="carts__item drop">{{ cart['text_' + selectedLang] }}</li>
                <li class="carts__item">
                  <span
                      class="carts__amount-items"
                      :class="{ active: getCartItemsTotalAmount || this.getCartPiesTotalAmount}"
                  >{{ this.getCartItemsTotalAmount + this.getCartPiesTotalAmount }}</span
                  >
                  <img src="@/assets/images/basket.svg" alt=""/>
                </li>
              </ul>
              <div class="cart__price">
                Всего:
                <div class="cart__sum">
                  {{ getCartTotalPrice }}
                  <span class="cart__currency">{{ wallet['text_' + selectedLang] }}</span>
                </div>
              </div>
            </div>
            <div v-show="getCartItemsTotalAmount && getCartPiesTotalAmount && !getData.chooseAllBool">
              {{ text1['text_' + selectedLang] }}<br><br>
              {{ text2['text_' + selectedLang] }}
              <div class="cart__buttonGroup">
                <div class="cart__button">
                  <button>
                    <app-button class="button--fill" @click="changeAllBool(true)">{{ yes['text_' + selectedLang] }}
                    </app-button>
                  </button>
                </div>
                <div class="cart__button">
                  <button>
                    <app-button class="button--fill" @click="changeAllBool(false)">{{ no['text_' + selectedLang] }}
                    </app-button>
                  </button>
                </div>
              </div>
            </div>
            <div class="cart__list" v-show="getCartItemsTotalAmount">
              <ul class="cart__items">
                <li
                    v-for="(itemInCart, name) in getCartItems"
                    :key="+name + +itemInCart.price"
                    class="cart__item"
                >
                  <div class="cart__name-product">
                    <span class="cart__amount">x{{ itemInCart.amount }}</span>
                    <span class="cart__title">{{ itemInCart['title_' + selectedLang] }}</span>
                    <span class="cart__price cart__price"
                    >{{ itemInCart.price }} {{ wallet['text_' + selectedLang] }}</span
                    >
                  </div>
                  <div class="cart__change-amount">
                    <div
                        class="cart__minus"
                        @click="
                      addItemInCart(
                        name,
                        --itemInCart.amount,
                        itemInCart.title,
                        itemInCart.price
                      )
                    "
                    >
                      <icon-minus/>
                    </div>
                    <div
                        class="cart__plus"
                        @click="
                      addItemInCart(
                        name,
                        ++itemInCart.amount,
                        itemInCart.title,
                        itemInCart.price
                      )
                    "
                    >
                      <icon-plus class="cart__plus"/>
                    </div>
                  </div>
                </li>
              </ul>
              <form action="" class="cart__form" v-show="!getData.allBool">
                <app-input
                    class="cart__input"
                    :type="'text'"
                    :placeholder="note['text_' + selectedLang]"
                    :nameInput="'cartNoteItems'"
                />
                <div class="cart__button">
                  <button @click="dropCart()">
                    <router-link :to="{ name: 'AppOrdering', params: { type: 'item'} }">
                      <app-button class="button--fill">{{ orderingText['text_' + selectedLang] }}</app-button>
                    </router-link>
                  </button>
                </div>
              </form>
            </div>
            <div class="cart__list" v-show="getCartPiesTotalAmount">
              <ul class="cart__items">
                <li
                    v-for="(pieInCart, name) in getCartPies"
                    :key="+name + +pieInCart.price"
                    class="cart__item"
                >
                  <div class="cart__name-product">
                    <span class="cart__amount">x{{ pieInCart.amount }}</span>
                    <span class="cart__title">{{ pieInCart['title_' + selectedLang] }}</span>
                    <span class="cart__price"
                    >{{ pieInCart.price }} {{ wallet['text_' + selectedLang] }}</span
                    >
                  </div>
                  <div class="cart__change-amount">
                    <div
                        class="cart__minus"
                        @click="
                addPieInCart(
                  name,
                  --pieInCart.amount,
                  pieInCart.title,
                  pieInCart.price
                )
              "
                    >
                      <icon-minus/>
                    </div>
                    <div
                        class="cart__plus"
                        @click="
                      addPieInCart(
                         name,
                         ++pieInCart.amount,
                          pieInCart.title,
                         pieInCart.price
                       )
                     "
                    >
                      <icon-plus class="cart__plus"/>
                    </div>
                  </div>
                </li>
              </ul>
              <form action="" class="cart__form" v-show="!getData.allBool">
                <app-input
                    class="cart__input"
                    :type="'text'"
                    :placeholder="note['text_' + selectedLang]"
                    :nameInput="'cartNotePies'"
                />
                <div class="cart__button">
                  <button @click="dropCart()">
                    <router-link :to="{ name: 'AppOrdering', params: { type: 'pie'} }">
                      <app-button class="button--fill">{{ orderingText['text_' + selectedLang] }}</app-button>
                    </router-link>
                  </button>
                </div>
              </form>
              <form action="" class="AppCart__form cart__form" v-show="getData.allBool">
                <app-input
                    class="cart__input"
                    :type="'text'"
                    :placeholder="note['text_' + selectedLang]"
                    :nameInput="'cartNotePies'"
                />
                <div class="cart__button">
                  <router-link :to="{ name: 'AppOrdering', params: { type: 'all'} }">
                    <app-button class="button--fill">{{ orderingText['text_' + selectedLang] }}</app-button>
                  </router-link>
                </div>
              </form>
            </div>
            <div v-show="!getCartItemsTotalAmount && !getCartPiesTotalAmount" class="cart__error">
              {{ cartNull['text_' + selectedLang] }}
            </div>
          </div>
          <div
              class="cart__close"
              :class="{ active: getCartIsDropped }"
              @click="dropCart()"
          ></div>
          <ul class="carts__items" @click="dropCart()">
            <li class="carts__item">{{ cart['text_' + selectedLang] }}</li>
            <li class="carts__item">
              <span
                  class="carts__amount-items"
                  :class="{ active: getCartItemsTotalAmount || this.getCartPiesTotalAmount }"
              >{{ getCartItemsTotalAmount + this.getCartPiesTotalAmount }}</span
              >
              <img src="@/assets/images/basket.svg" alt=""/>
            </li>
          </ul>
        </li>
        <li class="AppHeader__item">
          <div
              @click="openMenu"
              class="AppHeader__menu"
              :class="{ active: isActive }"
          >
            <span>{{ statusName }}</span>
            <ul
                class="AppHeader__menu-identification"
                :class="{ active: isActive }"
            >
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </li>
      </ul>
      <div class="menu__box" :class="{ active: isActive }" @click="openMenu">
        <ul class="drop__items">
          <li>
            <router-link to="/">
              <app-title class="title--medium drop__item">{{ homeText['text_' + selectedLang] }}</app-title>
            </router-link>
          </li>
          <li>
            <router-link to="/about">
              <app-title class="title--medium drop__item">{{ aboutUsText['text_' + selectedLang] }}</app-title>
            </router-link>
          </li>
          <li>
            <router-link to="/menu">
              <a href="#">
                <app-title class="title--medium drop__item">{{ menuText['text_' + selectedLang] }}</app-title>
              </a></router-link
            >
          </li>
          <li>
            <router-link to="/delivery">
              <app-title class="title--medium drop__item">
                {{ deliveryText['text_' + selectedLang] }}
              </app-title>
            </router-link
            >
          </li>
          <li>
            <router-link to="/pies">
              <app-title class="title--medium drop__item"
              >{{ pieText['text_' + selectedLang] }}
              </app-title>
            </router-link>
          </li>
          <li>
            <router-link to="/blog">
              <a href="#">
                <app-title class="title--medium drop__item">{{ blogText['text_' + selectedLang] }}</app-title>
              </a>
            </router-link>
          </li>
          <li>
            <router-link to="/contact">
              <a href="#">
                <app-title class="title--medium drop__item"
                >{{ contactsText['text_' + selectedLang] }}
                </app-title>
              </a>
            </router-link>
          </li>
          <li>
            <router-link to="/cart">
              <ul class="menu__box__cart carts carts__items">
                <li class="carts__item">{{ cart['text_' + selectedLang] }}</li>
                <li class="carts__item">
                  <span
                      class="carts__amount-items"
                      :class="{ active: getCartItemsTotalAmount || this.getCartPiesTotalAmount }"
                  >{{ getCartItemsTotalAmount + this.getCartPiesTotalAmount }}</span
                  >
                  <img src="@/assets/images/basket.svg" alt=""/>
                </li>
              </ul
              >
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import AppTitle from "./AppTitle";
import {mapActions, mapGetters} from "vuex";
import AppInput from "./AppInput.vue";
import AppButton from "./AppButton";
import iconMinus from "./svg/iconMinus";
import iconPlus from "./svg/iconPlus";


export default {
  name: "AppHeader",
  components: {
    AppTitle,
    AppInput,
    AppButton,
    iconMinus,
    iconPlus,
  },

  data() {
    return {
      isActive: false,
      statusName: "Меню",
      height: null,
      date: {
        text_ru: 'Пн-Вс: с 9:00 до 23:00',
        text_ua: 'Пн-Нд: з 9:00 до 23:00',
        text_pl: 'pon-niedz: od 9:00 do 23:00',
        text_en: 'Mon-Sun: from 9:00 to 23:00',
      },
      cart: {
        text_ru: 'Корзина',
        text_ua: 'Кошик',
        text_pl: 'Kosz',
        text_en: 'Cart',
      },
      wallet: {
        text_ru: 'грн',
        text_ua: 'грн',
        text_pl: 'hrywien',
        text_en: 'UAH',
      },
      text1: {
        text_ru: 'Чтобы испечь лучшый пирог нам нужен хотя бы день',
        text_ua: 'Щоб спекти топовий пиріг нам потрібен хоча б день',
        text_pl: 'Na upieczenie najlepszego ciasta potrzebujemy przynajmniej jednego dnia',
        text_en: 'We need at least a day to bake the best cake',
      },
      text2: {
        text_ru: 'Хотите заказать блюда из меню и пироги на одно время?',
        text_ua: 'Хочете замовити страви з меню і пироги на один час?',
        text_pl: 'Chcesz zamówić jednocześnie dania z karty i ciasta?',
        text_en: 'Do you want to order dishes from the menu and pies at the same time?',
      },
      yes: {
        text_ru: 'Да, конечно',
        text_ua: 'Так, звичайно',
        text_pl: 'Tak, oczywiście',
        text_en: 'Yes of course',
      },
      no: {
        text_ru: 'Нет, спасибо',
        text_ua: 'Ні, дякую',
        text_pl: 'Nie, dziękuję',
        text_en: 'No, thanks',
      },
      note: {
        text_ru: 'Примечание',
        text_ua: 'Ні, дякую',
        text_pl: 'Nie, dziękuję',
        text_en: 'Note',
      },
      orderingText: {
        text_ru: 'Заказать домой',
        text_ua: 'Замовити додому',
        text_pl: 'Zamów do domu',
        text_en: 'Order home',
      },
      cartNull: {
        text_ru: 'Сейчас в Вашей корзине ничего нет.',
        text_ua: 'Зараз у Вашій корзині нічого немає.',
        text_pl: 'Twój koszyk jest obecnie pusty.',
        text_en: 'Your cart is currently empty.',
      },
      homeText: {
        text_ru: 'Главная',
        text_ua: 'Головна',
        text_pl: 'Główny',
        text_en: 'The main',
      },
      aboutUsText: {
        text_ru: 'О нас',
        text_ua: 'Про нас',
        text_pl: 'O nas',
        text_en: 'About us',
      },
      menuText: {
        text_ru: 'Меню',
        text_ua: 'Меню',
        text_pl: 'Menu',
        text_en: 'Menu',
      },
      deliveryText: {
        text_ru: 'Доставка',
        text_ua: 'Доставка',
        text_pl: 'Dostawa',
        text_en: 'Delivery',
      },
      pieText: {
        text_ru: 'Пироги на заказ',
        text_ua: 'Пироги на замовлення',
        text_pl: 'Ciasta na zamówienie',
        text_en: 'Pies to order',
      },
      blogText: {
        text_ru: 'Блог',
        text_ua: 'Блог',
        text_pl: 'Blog',
        text_en: 'Blog',
      },
      contactsText: {
        text_ru: 'Контакты',
        text_ua: 'Контакти',
        text_pl: 'Łączność',
        text_en: 'Contacts',
      }
    };
  },
  methods: {
    updateHeight: function (evt, el) {
      if (window.scrollY > 50) {
        el.classList.add("scroll");
      } else {
        el.classList.remove("scroll");
      }
    },

    isActiveMethods: function () {
      if (this.isActive) {
        this.statusName = "Меню";
        this.isActive = false;
        return this.isActive;
      } else {
        this.statusName = "Закрыть";
        this.isActive = true;
        return this.isActive;
      }
    },

    openMenu(getIsDropMenuActive = true) {
      if (getIsDropMenuActive) {
        this.isActiveMethods();
        this["isDropMenuActive"](this.isActive);
      }
    },

    clickLogo() {
      if (["getIsDropMenuActive"]) {
        this.isActive = false;
        this.statusName = "Меню";
        this["isDropMenuActive"](this.isActive);
      }
    },

    addItemInCart(id, amount, title, price) {
      console.log(id, amount, title, price);
      this.addCartItem({
        id: id,
        amount: amount,
        title: title,
        price: price,
      });
      this.updateItemAmount({ id, amount });
    },

    addPieInCart(id, amount, title, price) {
      this.addCartPie({
        id: id,
        amount: amount,
        title: title,
        price: price,
      });
      this.updatePieAmount({ id, amount })
    },
    changeAllBool(bool) {
      this.changeData({key: 'allBool', value: bool})
      this.changeData({key: 'chooseAllBool', value: true})
    },

    ...mapActions([
      "changeItemsInCart",
      "dropCart",
      "addCartItem",
      'addCartPie',
      'isDropMenuActive',
      "decrementAmountItemsInBasket",
      'updateItemAmount',
      'updatePieAmount',
    ]),

    ...mapActions("ordering", ['changeData',])
  },

  computed: {
    ...mapGetters([
      "getCartItemsTotalAmount",
      'getCartPiesTotalAmount',
      "getCartTotalPrice",
      "getCartIsDropped",
      "getCartItems",
      'getCartPies',
      "getIsDropMenuActive",
      "header",
    ]),
    ...mapGetters('ordering', ['getData']),
    ...mapGetters('lang', ['selectedLang']),

  },
};
</script>

<style lang="scss">
.AppHeader {
  @include flex(center, center, row, nowrap);
  right: 0;
  left: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10000;
  @include transition(box-shadow);

  &.scroll {
    box-shadow: inset 0 110px 15px -15px $body-color;

    @include media-d-m($screen-netbook) {
      box-shadow: inset 0 85px 20px -5px $body-color;
    }
  }

  &__logo {
    width: 108px;
  }

  &__inner {
    padding: 30px 0 40px 0;
    position: relative;
    @include media-d-m($screen-netbook) {
      padding: 30px 0;
    }
  }

  &__items {
    @include flex(space-between, center, row, nowrap);
    position: relative;

    z-index: 5;

    @include media-d-m($screen-netbook) {
      @include flex(space-between, center, row-reverse, nowrap);
    }
  }

  &__contact {
    @include text($h15, 400, $light-yellow, $f-montserrat);

    @include flex(center, center, row, nowrap);
    height: 100%;
    @include media-d-m($screen-netbook) {
      display: none;
    }
  }

  &__tel {
    @include text($h15, 400, $light-yellow, $f-montserrat);

    @include media-d-m($screen-netbook) {
      display: none;
    }
  }

  &__menu {
    @include text($h15, 400, $light-yellow, $f-montserrat);
    @include flex(center, center, row, nowrap);
    cursor: pointer;
    transition-property: width, background-color, color;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    margin-left: 9px;
    @include media-d-m($screen-netbook) {
      margin-left: 0;
    }

    & span {
      margin-right: 25px;
      @include media-d-m($screen-netbook) {
        display: none;
      }
    }

    &:hover {
      color: $yellow;
    }

    &:hover .AppHeader__menu-identification li {
      width: 40px;
      background-color: $yellow;
    }
  }

  &__menu.active {
    margin-left: -9px;
    @include media-d-m($screen-netbook) {
      margin-left: 0;
    }
  }

  &__menu-identification {
    width: 40px;
    height: 20px;
    cursor: pointer;

    & li {
      width: 40px;
      height: 2px;
      background-color: $light-yellow;
      transition-property: width, background-color, transform, opacity;
      transition-duration: 0.2s;
      transition-timing-function: ease;

      &:not(:last-of-type) {
        margin-bottom: 7px;
      }

      &:nth-child(1) {
        width: 34px;
      }

      &:nth-child(3) {
        width: 24px;
        float: right;
      }
    }
  }

  &__menu-identification.active li {
    &:nth-child(1) {
      position: relative;
      bottom: -9px;
      width: 40px;
      transform: rotate(-45deg);
    }

    &:nth-child(2) {
      opacity: 0;
    }

    &:nth-child(3) {
      position: relative;
      top: -9px;
      width: 40px;
      transform: rotate(45deg);
    }
  }

  &__carts {
    cursor: pointer;
    display: none;

    &.active {
      display: block;

      @include media-d-m($screen-netbook) {
        display: none;
      }
    }
  }
}

.carts {
  @include text($h16, 300, $light-yellow, $f-montserrat);

  &__items {
    @include flex(center, center, row, nowrap);
    cursor: pointer;
  }

  &__item {
    @include flex(center, center, row, nowrap);

    &.drop {
      color: $yellow;
    }
  }

  &__amount-items {
    @include text($h13, 400, $body-color, $f-montserrat);
    min-width: 18px;
    min-height: 18px;
    border-radius: 18px;
    position: relative;
    right: -12px;
    bottom: -7px;
    text-align: center;
    background-color: $light-yellow;
    display: block;
    border: 1px solid $body-color;

    &.active {
      min-width: 18px;
      background-color: $yellow;
      padding: 0 3px;
    }
  }
}

.menu {
  &__box {
    @include size(calc(45% + 100%), calc(100vh + 90px));
    @include flex(center, flex-start, column, nowrap);
    position: absolute;
    visibility: hidden;
    top: 0;
    left: -200%;
    z-index: 4;
    padding-left: 100%;

    @include media-d-m($screen-desktop-large) {
      padding-left: calc(100% + 30px);
    }

    @include media-d-m($screen-netbook) {
      @include flex(flex-start, center, row, nowrap);
      width: 200%;
    }

    list-style: none;

    background-color: $body-color;
    box-shadow: 62px 3px 65px 40px rgba(21, 19, 4, 1);
    -webkit-box-shadow: 62px 3px 65px 40px rgba(21, 19, 4, 1);
    -moz-box-shadow: 62px 3px 65px 40px rgba(21, 19, 4, 1);

    @include transition(visibility, left);

    &.active {
      left: calc(0px - 100%);
      visibility: initial;
    }

    &__cart {
      display: none;

      @include media-d-m($screen-netbook) {
        @include flex(flex-start, center, row, nowrap);
      }
    }
  }
}

.drop__item {
  margin-bottom: 30px;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease;

  &:hover {
    color: $yellow;
  }

  @include media-d-m($screen-desktop-large) {
    font-size: $h31;
    margin-bottom: 15px;
  }
}

.cart {
  &__close.active {
    position: absolute;
    top: -45px;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    max-width: 100%;

    width: 100%;
    z-index: 998;
  }

  &__drop {
    cursor: default;
    @include flex(flex-start, flex-start, column, nowrap);
    top: 0;
    width: 340px;
    min-height: 200px;
    padding: 9px 24px 24px 24px;
    position: absolute;
    background: $body-color;
    border: 1px solid $light-yellow;
    z-index: 999;
    margin-left: -25px;
  }

  &__banner {
    width: 100%;
    @include flex(space-between, center, row, nowrap);
    margin-bottom: 55px;
  }

  &__price {
    @include flex(center, center, row, nowrap);
    @include text($h16, 300, $grey, $f-montserrat);
  }

  &__sum {
    margin-left: 21px;
    color: $yellow;
  }

  &__currency {
    font-size: 10px;
  }

  &__amount {
    @include text($h15, 400, $grey, $f-montserrat);
    display: inline-flex;
    min-width: 17px;
    margin-right: 18px;
  }

  &__title {
    @include text($h16, 300, $light-yellow, $f-montserrat);
  }

  &__items {
    margin-bottom: 30px;
    width: 100%;
  }

  &__item {
    @include flex(space-between, center, row, nowrap);
    width: 100%;
  }

  &__change-amount {
    @include flex(center, center, row, nowrap);
  }

  &__minus {
    @include flex(center, center, row, nowrap);
    margin-right: 10px;
    cursor: pointer;
    padding: 5px 0px;
  }

  &__plus {
    @include flex(center, center, row, nowrap);
    cursor: pointer;
  }

  &__input {
    margin-bottom: 55px;
    width: 100%;
  }

  &__form {
    width: 100%;
  }

  &__error {
    margin-top: -30px;
    margin-bottom: 30px;
  }

  &__buttonGroup {
    display: flex;
    flex-direction: row;

    .button {
      min-width: 100px;
      margin: 10px;
      padding: 0 10px;
    }
  }

  &__list {
    width: 100%;
    margin-top: 15px;
  }
}
</style>
