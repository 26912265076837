<template>
  <div id="app">
    <component :is="layout">
      <slot />
    </component>
  </div>
</template>

<script>
import PublicLayout from '@/layouts/public/main.vue';
import DefaultLayout from '@/layouts/default/main.vue';

export default {
  name: 'App',
  components: {
    PublicLayout,
    DefaultLayout,
  },
  computed: {
    layout() {
      console.log('LATOUT_COMPUTED::', this.$store.state)
      return this.$store.state?.layout ?? '';
    },
  },
  mounted() {
    let store = this.$store;

    console.log('App::mounted dispatch');
    this.$store.dispatch('apiInitDispatch');

    // Update page title.
    this.$store.watch(
      (state) => {
        return state.title;
      },
      (title) => {
        document.title = title.toString();
        console.log('title updated');
      },
      {
        deep: true,
      }
    );
  },
};
</script>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
