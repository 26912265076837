import axios  from 'axios'
import router from './router'

export const http = {
    install (Vue, options) {
        Vue.prototype.$http = Vue.http = window.axios = axios.create()
        // if (process.env.NODE_ENV !== 'production') {
        //     Vue.prototype.$http.defaults.headers.common['XDEBUG_SESSION_START'] = 'PHPSTORM';
        // }

        // Vue.prototype.$http.defaults.headers.common['Access-Control-Allow-Origin'] = 'http://localhost:8081';
    }
}

export { router }
