import { API_BASE_URL } from '@/constants';

export default {
    dropCart({commit}) {
        commit('updateCartDropped');
    },

    changeItemsInCart({commit}) {
        commit('updateItemsInCart');
    },

    clearCart({commit}) {
        commit('clearCart')
    },

    addCartItem({commit}, {id, amount, title, price, title_ru, title_ua, title_en, title_pl}) {
        console.log('CART_ITEM_ID::', id)
        commit('addCartItem', {
            id: id,
            amount: amount,
            title: title,
            title_ru: title_ru,
            title_ua: title_ua,
            title_en: title_en,
            title_pl: title_pl,
            price: price,
        });
    },

    addCartPie({commit}, {id, amount, title, price, title_ru, title_ua, title_en, title_pl}) {
        commit('addCartPie', {
            id: id,
            amount: amount,
            title: title,
            title_ru: title_ru,
            title_ua: title_ua,
            title_en: title_en,
            title_pl: title_pl,
            price: price,
        });
    },

    removeCartItem({commit}, {id, amount, title, price}) {
        commit('removeItemsInCart', {
            id: id,
            amount: amount,
            title: title,
            price: price,
        });
    },
    removeCartPie({commit}, {id, amount, title, price}) {
        commit('removePiesInCart', {
            id: id,
            amount: amount,
            title: title,
            price: price,
        });
    },

};
