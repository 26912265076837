export default {
    // getCheesecake(state) {
    //     return state.cheesecake;
    // },

    // getPies(state) {
    //     return state.pies;
    // },

    getPosts(state) {
        return state.posts;
    },

    getSplitPosts(state) {
        const blog = [];
        let posts = [];
        let postKey = 0;

        state.posts.forEach((value, key) => {
            console.log('POST_GETTER::KEY', key)
            posts.push(value);

            if ((key + 1) % 4 === 0 || (key + 1) === state.posts.length) {
                ++postKey;
                blog.push(posts);
                posts = [];
            }
        })

        return blog;
    },

    getSectionBlog__1(state) {
        return state.data.sectionBlog__1;
    },
}
