export default {
    // getLangsList(state) {
    //     return state.list;
    // },
    //
    // selectedLang(state) {
    //     return state.selected;
    // }

    getCheesecakePageData(state) {
        return state.page.cheesecake
    },

    getPiesPageData(state) {
        return state.page.pies
    },

    getPiesList(state) {
        return state.pies.filter(pie => pie.type === 'pie');
    },

    getCheesecakeList(state) {
        return state.pies.filter(cheesecake => cheesecake.type === 'cheesecake')
    },

    getPieCountById: (state) => (id) => {
        let count = 0;
        state.pies.some((pie) => {
            if (pie.pie_id === id) {
                count = pie.selected_count;
                return true;
            }
        })
        return count;
    }
}
