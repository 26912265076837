// Testing
export const DEBUG = true;

// Backend API endpoints
// export const API_BASE_URL = 'http://tarta.local/api/';
export const API_BASE_URL = 'https://tarta-api.mrsgreenwich.com/api/';
// export const BASE_URL = 'http://tarta.local/';
export const BASE_URL = 'https://tarta-api.mrsgreenwich.com/';
// export const API_BASE_URL = 'https://api.tarta.mrsgreenwich.com/api/';

export const REFRESH_TOKEN_URL = '/auth';

/**
 * Key for local storage.
 *
 * Set the key to use in local storage to hold persistant data. If logged in,
 * you can see this key by going to Chrome > dev tools > application tab,
 * then choosing "Local Storage" and "http://localhost:8080".
 *
 * @type {string}
 */
export const STORAGE_KEY = 'tarta-storage';
