import { API_BASE_URL } from '@/constants';

export default {
    initMenu: async function({ state, commit }) {
        const typesApiResponse = await axios.get(API_BASE_URL + 'menu');

        const [data, menuId] = [{}, []];
        const keys = [];

        console.log('typesApiResponse', typesApiResponse);
        typesApiResponse.data.resource.data.forEach((elem) => {
            keys.push(elem.id);
            menuId.push(elem.menu_id);
            data[elem.id] = { ...elem, key: undefined, isActive: false };
        });

        console.log('keys', keys);

        // console.log('menuId', menuId);
        // const productsApiResponse = await axios.post(
        //   API_BASE_URL + 'items/menu',
        //   { id: menuId }
        // );
        // console.log('productsApiResponse fetched:: ', productsApiResponse);
        commit('update', { key: 'types', data });
        commit('update', { key: 'typesDelivery', data });
        // state.types.firstDish.isActive = true;
        commit('updateKeys', { data: keys });
        // commit('updateProducts', { data: productsApiResponse.data.data });
    },

    clear({ state, commit, rootState, dispatch }) {
        commit('clear');
    },
    update({ state, commit, rootState }, { key, data }) {
        commit('update', { key, data });
    },

    selectType({ commit }, { key, data }) {
        commit('selectType', { key, value: data });
    },
    selectTypeAllDisable({ commit }, { key, data }) {
        commit('selectTypeAllDisable', { key, value: data });
    },

    updateItemAmount({ commit, state }, { id, amount }) {
        console.log('updateItemAmount::state.tepes!!!!', state.types);
        console.log('updateItemAmount::id!!!!', +id);
        console.log('updateItemAmount::amount!!!!', amount);
        id = +id;
        let [menuIndex, itemIndex, item] = [];

        Object.keys(state.types).forEach(localMenuIndex => {
            state.types[localMenuIndex].items.forEach((localItem, localItemIndex) => {
                if (localItem.item_id === id) {
                    [menuIndex, itemIndex, item] = [localMenuIndex, localItemIndex, localItem];
                }
            })
        })

        if (typeof menuIndex !== undefined) {
            console.log('PUSHING_AMOUNT+++++::', amount)
            item.count_selected = amount;
            commit('updateItemAmount', { menuIndex, itemIndex, item })
        }
    }
};
