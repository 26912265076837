<template>
  <ul class="social social__items">
    <li class="social__li">
      <a href="#" class="social__item social__item--tripadvisor"></a>
    </li>
    <li class="social__li">
      <a href="#" class="social__item social__item--facebook"></a>
    </li>
    <li class="social__li">
      <a href="#" class="social__item social__item--instagram"></a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'AppSocial',
};
</script>

<style lang="scss">
.social {
  @include flex(flex-start, center, row, nowrap);
  &__li:not(:last-of-type) {
    margin-right: 35px;
  }

  &__item {
    @include size(40px, 30px);

    &--tripadvisor {
      background: url(../../../assets/images/social__tripadvisor.png) center center
        no-repeat;
    }
    &--facebook {
      background: url(../../../assets/images/social__facebook.png) center center
        no-repeat;
    }
    &--instagram {
      background: url(../../../assets/images/social__instagram-1.png) center center
        no-repeat;
      transition-property: background-image;
      transition-duration: 0.2s;
      transition-timing-function: ease;
      &:hover {
        background-image: url(../../../assets/images/social__instagram-2.png);
      }
    }
  }
}
</style>
