export default {
    // getCheesecake(state) {
    //     return state.cheesecake;
    // },

    // getPies(state) {
    //     return state.pies;
    // },

    getLangsList(state) {
        return state.list;
    },

    selectedLang(state) {
        return state.selected;
    }
}
