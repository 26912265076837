<template>
  <div
      class="AppFooter"
      :style="{
      'background-image':
        'url(' +
        require('@/assets/images/footer/' +
          this['footer'].data.imageUrl +
          '.png') +
        ')',
    }"
      :class="{
      noBacground: this['footer'].data.imageUrl === 'none',
      dNone: !this['footer'].data.display,
    }"
  >
    <div class="AppFooter__inner container">
      <div
          class="AppFooter__delivery"
          v-show="this['footer'].data.showDelivery"
      >
        <app-title class="AppFooter__title title--medium">
          {{ this["footer"].data["title_" + selectedLang] }}
        </app-title>
        <router-link to="/delivery">
          <app-button class="button--fill">
            {{ this["footer"].data["buttonTitle_" + selectedLang] }}
          </app-button>
        </router-link
        >
      </div>
      <div
          class="AppFooter__nav"
          :class="{ 'with-out-allergy': !this['footer'].data.showAllergy }"
          v-show="this['footer'].data.showNav"
      >
        <div
            class="AppFooter__allergy"
            v-show="this['footer'].data.showAllergy"
        >
          <a href="">{{allergen['text_' + selectedLang]}}</a>
        </div>
        <div class="AppFooter__up">
          Наверх
          <a v-smooth-scroll :href="'#' + this['footer'].data.href">
            <app-button
                :href="'#' + this['footer'].data.href"
                class="AppFooter__button button--footer button--stroke"
            >
              <icon-arrow
                  class="AppFooter__arrow iconArrow--up iconArrow--yellow"
              />
            </app-button
            >
          </a>
        </div>
      </div>
      <div class="AppFooter__mainbar">
        <div class="AppFooter__contact">
          <p><a href="tel:+38 (050) 372 27 30">+38 (050) 372 27 30</a></p>
          <p>
            <a href="mailto:mrs.greenwich@gmail.com">mrs.greenwich@gmail.com</a>
          </p>
        </div>
        <div class="AppFooter__logo-group">
          <img src="@/assets/images/footer__logo__group.svg" alt=""/>
        </div>
        <div class="AppFooter__logo">
          <img src="@/assets/images/logo__white.png" alt="" height="30px"/>
        </div>
        <AppSocial class="AppFooter__social"/>
      </div>
      <div class="AppFooter__copyright">© Powered by I2Unix LLC</div>
    </div>
  </div>
</template>

<script>
import AppTitle from "../AppTitle";
import AppButton from "../AppButton";
import AppSocial from "../AppSocial";
import IconArrow from "../svg/iconArrow.vue";

import {mapGetters} from "vuex";

export default {
  name: "AppFooter",
  components: {
    AppTitle,
    AppButton,
    AppSocial,
    IconArrow,
  },
  computed: {
    ...mapGetters({
      footer: "footer",
    }),
    ...mapGetters(['getSortedTexts']),
    ...mapGetters('lang', ['selectedLang'])
  },
  props: {
    // title: String,
    // buttonTitle: String,
    // imageUrl: String,
  },
  data() {
    return {
      allergen: {
        text_ru: 'Список алергенов',
        text_ua: 'Список алергенів',
        text_pl: 'Lista alergenów',
        text_en: 'Allergen List',
      }
    };
  },
};
</script>

<style lang="scss">
$style: "AppFooter";
.#{$style} {
  background-size: cover;
  background-position: center;
  position: relative;
  box-shadow: inset 0 80px 50px -5px $body-color;

  &:not(.noBacground) {
    &::after {
      content: "";
      position: absolute;
      @include position();
      background: $body-color;
      opacity: 0.8;
      z-index: 2;
    }
  }

  &.dNone {
    display: none;
  }

  &__inner {
    position: relative;
    padding-top: 50px;
    z-index: 3;
  }

  &__delivery {
    margin-bottom: 150px;

    @include media-d-m($screen-netbook) {
      margin-bottom: 70px;
    }
  }

  &__title {
    max-width: 510px;
    margin-bottom: 60px;

    @include media-d-m($screen-netbook) {
      margin-bottom: 30px;
    }
  }

  &__nav {
    @include flex(space-between, center, row, nowrap);
    margin-bottom: 35px;

    &.with-out-allergy {
      @include flex(flex-end, center, row, nowrap);
    }

    @include media-d-m($screen-netbook) {
      display: none;
    }
  }

  &__allergy {
    opacity: 0.8;
    border-bottom: 1px solid $light-yellow;

    & a {
      @include text($h16, 300, $light-yellow, $f-montserrat);
    }
  }

  &__up {
    @include flex(center, center, row, nowrap);
    @include text($h16, 300, $yellow, $f-montserrat);
  }

  &__button {
    @include flex(center, center, row, nowrap);
    @include size(45px, 45px);
    font-size: $h20;
    margin-left: 20px;

    &:hover .#{$style}__arrow {
      fill: $body-color;
    }
  }

  &__mainbar {
    @include flex(space-between, center, row, nowrap);
    margin-bottom: 37px;

    @include media-d-m($screen-netbook) {
      @include flex(space-between, center, row, wrap);
    }
  }

  &__contact {
    @include media-d-m($screen-netbook) {
      order: -2;
      width: 100%;
      margin-bottom: 35px;
    }

    & a {
      @include text($h16, 300, $light-yellow, $f-montserrat);
    }

    & p:not(:last-of-type) {
      margin-bottom: 9px;
    }
  }

  &__social {
    @include media-d-m($screen-netbook) {
      order: -1;
      width: 100%;
      margin-bottom: 50px;
    }
  }

  &__copyright {
    @include text($h16, 300, $light-yellow, $f-montserrat);
    @include flex(center, center, row, nowrap);
    padding-bottom: 38px;
  }

  &--without-nav .footer__nav {
    visibility: hidden;
  }
}
</style>
