import state from "./state";

export default {
    // loadState(state, loaded) {
    //     state = { ...state, ...loaded }
    // },
    //
    // selectlang(state, lang) {
    //     state.selected = lang;
    // }

    initPies(state, { pies }) {
        state.pies = pies;
    },

    updatePieAmount(state, { pieIndex, pie }) {
        state.pies[pieIndex] = pie;
    }
}
