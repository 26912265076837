/**
 * Every route becomes a chunk, loaded only when used.
 * Reduces size of initial App load.
 *
 * Here you can bind data to routes and call the dispatcher from "./router.js" file
 */
const routes = [
  {
    name: 'AppMain',
    path: '/',
    component: () => import('@/modules/app/views/AppMain'),
    title: 'Tarta',
    layout: 'DefaultLayout',
    pageKey: 'home',
    header: {
      data: {
        showCart: true,
      },
    },
    footer: {
      componentName: 'AppMainFooter',
      data: {
        showCart: true,
      },
    },
    isPublic: true,
  },
  {
    path: '/pies',
    name: 'AppPies',
    component: () => import('@/modules/app/modules/pies/views/AppPies'),
    title: 'Pies',
    layout: 'DefaultLayout',
    pageKey: 'pies',
    header: {
      componentName: 'AppHeader',
      data: {
        showCart: true,
      },
    },
    footer: {
      componentName: 'AppFooter',
      data: {
        showDelivery: true,
        display: true,
        showAllergy: true,
        title_ru: 'Закажите лучшую еду прямо домой',
        title_ua: 'Замовте кращу їжу прямо додому',
        title_en: 'Book the best food directly to your home',
        title_pl: 'Zamówić najlepsze jedzenie prosto do domu',
        imageUrl: 'footer__bg-1',
        buttonTitle_ru: 'Заказать домой',
        buttonTitle_ua: 'Замовити додому',
        buttonTitle_en: 'Order home',
        buttonTitle_pl: 'Zamów do domu',
        showNav: true,
        href: 'AppPies',
      },
    },
    isPublic: true,
  },
  {
    path: '/ordering/:type',
    name: 'AppOrdering',
    component: () => import('@/modules/app/modules/ordering/views/AppOrdering'),
    title: 'Ordering',
    layout: 'DefaultLayout',
    pageKey: 'ordering',
    header: {
      componentName: 'AppHeader',
      data: {
        showCart: true,
      },
    },
    footer: {
      componentName: 'AppFooter',
      data: {
        display: true,
        imageUrl: 'none',
        title_ru: 'Закажите лучшую еду прямо домой',
        title_ua: 'Замовте кращу їжу прямо додому',
        title_en: 'Book the best food directly to your home',
        title_pl: 'Zamówić najlepsze jedzenie prosto do domu',
        buttonTitle_ru: 'Заказать домой',
        buttonTitle_ua: 'Замовити додому',
        buttonTitle_en: 'Order home',
        buttonTitle_pl: 'Zamów do domu',
      },
    },

    isPublic: true,
  },
  {
    path: '/about',
    name: 'AppAbout',
    component: () => import('@/modules/app/views/AppAbout'),
    title: 'About Us',
    layout: 'DefaultLayout',
    pageKey: 'about_us',
    header: {
      data: {
        showCart: true,
      },
    },
    footer: {
      componentName: 'AppFooter',
      data: {
        showDelivery: true,
        display: true,
        class: 'footer--without-nav',
        title: 'Закажите сейчас и убедитесь',
        imageUrl: 'footer__bg-2',
        title_ru: 'Закажите сейчас и убедитесь',
        title_ua: 'Замовте зараз і переконайтеся',
        title_en: 'Order now and make sure',
        title_pl: 'Zamów teraz i upewnij',
        buttonTitle_ru: 'Заказать домой',
        buttonTitle_ua: 'Замовити додому',
        buttonTitle_en: 'Order home',
        buttonTitle_pl: 'Zamów do domu',
      },
    },
    isPublic: true,
  },
  {
    path: '/menu',
    name: 'AppMenu',
    component: () => import('@/modules/app/modules/menu/components/AppMenu'),
    title: 'Menu',
    layout: 'DefaultLayout',
    pageKey: 'menu',
    header: {
      data: {
        showCart: true,
      },
    },
    footer: {
      componentName: 'AppFooter',
      data: {
        showDelivery: true,
        display: true,
        class: 'footer--without-nav',
        title_ru: 'Закажите лучшую еду прямо домой',
        title_ua: 'Замовте кращу їжу прямо додому',
        title_en: 'Book the best food directly to your home',
        title_pl: 'Zamówić najlepsze jedzenie prosto do domu',
        buttonTitle_ru: 'Заказать домой',
        buttonTitle_ua: 'Замовити додому',
        buttonTitle_en: 'Order home',
        buttonTitle_pl: 'Zamów do domu',
        imageUrl: 'footer__bg-1',
      },
    },
    isPublic: true,
  },
  {
    path: '/contact',
    name: 'AppContact',
    component: () => import('@/modules/app/views/AppContact'),
    title: 'Contact',
    layout: 'DefaultLayout',
    pageKey: 'contacts',
    header: {
      data: {
        showCart: true,
      },
    },
    footer: {
      componentName: 'AppFooter',
      data: {
        showDelivery: false,
        display: true,
        class: 'footer--without-nav',
        title_ru: 'Закажите лучшую еду прямо домой',
        title_ua: 'Замовте кращу їжу прямо додому',
        title_en: 'Book the best food directly to your home',
        title_pl: 'Zamówić najlepsze jedzenie prosto do domu',
        buttonTitle_ru: 'Заказать домой',
        buttonTitle_ua: 'Замовити додому',
        buttonTitle_en: 'Order home',
        buttonTitle_pl: 'Zamów do domu',
        imageUrl: 'none',
        showNav: false,
        showAllergy: false,
      },
    },
    isPublic: true,
  },
  {
    name: 'AppBlog',
    path: '/blog',
    component: () => import('@/modules/app/modules/blog/views/AppBlog'),
    title: 'Blog',
    layout: 'DefaultLayout',
    pageKey: 'blog',
    header: {
      data: {
        showCart: true,
      },
    },
    footer: {
      componentName: 'AppFooter',
      data: {
        showDelivery: false,
        display: true,
        class: 'footer--without-nav',
        title_ru: '',
        title_ua: '',
        title_en: '',
        title_pl: '',
        buttonTitle_ru: 'Заказать домой',
        buttonTitle_ua: 'Замовити додому',
        buttonTitle_en: 'Order home',
        buttonTitle_pl: 'Zamów do domu',
        imageUrl: 'none',
        showNav: true,
        showAllergy: false,
        href: 'AppBlog',
      },
    },
    isPublic: true,
  },
  {
    name: 'AppDelivery',
    path: '/delivery',
    component: () => import('@/modules/app/views/AppDelivery'),
    title: 'Delivery',
    layout: 'DefaultLayout',
    pageKey: 'delivery',
    header: {
      data: {
        showCart: true,
      },
    },
    footer: {
      componentName: 'AppFooter',
      data: {
        showDelivery: false,
        display: true,
        class: 'footer--without-nav',
        title_ru: 'Закажите лучшую еду прямо домой',
        title_ua: 'Замовте кращу їжу прямо додому',
        title_en: 'Book the best food directly to your home',
        title_pl: 'Zamówić najlepsze jedzenie prosto do domu',
        buttonTitle_ru: 'Заказать домой',
        buttonTitle_ua: 'Замовити додому',
        buttonTitle_en: 'Order home',
        buttonTitle_pl: 'Zamów do domu',
        imageUrl: 'none',
        showNav: false,
        showAllergy: false,
      },
    },
    isPublic: true,
  },

  {
    name: 'AppCart',
    path: '/cart',
    component: () => import('@/modules/app/modules/cart/views/AppCart'),
    title: 'Cart',
    layout: 'DefaultLayout',
    pageKey: 'cart',
    header: {
      data: {
        showCart: true,
      },
    },
    footer: {
      componentName: 'AppFooter',
      data: {
        display: false,
        imageUrl: 'none',
        title_ru: 'Закажите лучшую еду прямо домой',
        title_ua: 'Замовте кращу їжу прямо додому',
        title_en: 'Book the best food directly to your home',
        title_pl: 'Zamówić najlepsze jedzenie prosto do domu',
        buttonTitle_ru: 'Заказать домой',
        buttonTitle_ua: 'Замовити додому',
        buttonTitle_en: 'Order home',
        buttonTitle_pl: 'Zamów do domu',
        showNav: false,
        showAllergy: false,
      },
    },
    isPublic: true,
  },
  {
    name: 'AppErrorPage',
    path: '*',
    component: () => import('@/modules/app/views/AppErrorPage'),
    title: 'Error',
    layout: 'DefaultLayout',
    pageKey: 'error',
    header: {
      data: {
        showCart: true,
      },
    },
    footer: {
      componentName: 'AppFooter',
      data: {
        display: true,
        imageUrl: 'none',
        title_ru: 'Закажите лучшую еду прямо домой',
        title_ua: 'Замовте кращу їжу прямо додому',
        title_en: 'Book the best food directly to your home',
        title_pl: 'Zamówić najlepsze jedzenie prosto do domu',
        buttonTitle_ru: 'Заказать домой',
        buttonTitle_ua: 'Замовити додому',
        buttonTitle_en: 'Order home',
        buttonTitle_pl: 'Zamów do domu',
        showNav: false,
        showAllergy: false,
      },
    },
    isPublic: true,
  },
];

export default routes;
