<template>
  <div class="AppMainFooter">
    <div class="AppMainFooter__inner container">
      <div class="AppMainFooter__topbar">
        <div class="AppMainFooter__banner">
          <div class="AppMainFooter__wrapper">
            <div class="AppMainFooter__block">
              <app-title
                  class="AppMainFooter__title title--small title--montserrat"
              >{{ where['text_' + selectedLang] }}
              </app-title
              >
              <a target="blank" href="https://goo.gl/maps/f8FundFhXomE9mu3A">
                <app-subtitle class="AppMainFooter__subtitle subtitle--medium"
                >{{ address['text_' + selectedLang] }}
                </app-subtitle
                >
              </a
              >
            </div>
          </div>
          <div class="AppMainFooter__wrapper">
            <div class="AppMainFooter__block">
              <app-title
                  class="AppMainFooter__title title--small title--montserrat"
              >{{ when['text_' + selectedLang] }}
              </app-title
              >
              <app-subtitle class="AppMainFooter__subtitle subtitle--medium"
              >{{ date['text_' + selectedLang] }}
              </app-subtitle
              >
            </div>
          </div>
          <div class="AppMainFooter__wrapper">
            <div class="AppMainFooter__block">
              <app-title
                  class="AppMainFooter__title title--small title--montserrat"
              >{{contact['text_' + selectedLang]}}
              </app-title
              >
              <a href="tel:+380503722730">
                <app-subtitle class="AppMainFooter__subtitle subtitle--medium"
                >+38 (050) 372 27 30
                </app-subtitle
                >
              </a
              >
              <a href="mailto:mrs.greenwich@gmail.com">
                <app-subtitle class="AppMainFooter__subtitle subtitle--medium"
                >mrs.greenwich@gmail.com
                </app-subtitle
                >
              </a>
            </div>
            <div class="AppMainFooter__block">
              <app-title
                  class="AppMainFooter__title title--small title--montserrat"
              >{{media['text_' + selectedLang]}}
              </app-title
              >
              <app-social/>
            </div>
          </div>
        </div>
        <div class="AppMainFooter__map">
          <app-map></app-map>
        </div>
      </div>
      <div class="AppMainFooter__mainbar">
        <div class="AppMainFooter__to-order">
          <app-subtitle class="AppMainFooter__subtitle subtitle--medium"
          >{{text1['text_' + selectedLang]}}
          </app-subtitle
          >
          <router-link to="/delivery">
            <app-button class="AppMainFooter__button button--fill"
            >{{delivery1['text_' + selectedLang]}}
            </app-button
            >
          </router-link>
        </div>
        <img
            class="AppMainFooter__logo-group"
            src="@/assets/images/footer__logo__group.svg"
            alt=""
        />
        <img
            src="@/assets/images/logo__white.png"
            alt=""
            class="AppMainFooter__logo"
        />
      </div>
      <span class="AppMainFooter__copyright"> © Powered by I2Unix LLC </span>
    </div>
  </div>
</template>

<script>
import AppSubtitle from "../AppSubtitle.vue";
import AppTitle from "../AppTitle.vue";
import AppSocial from "../AppSocial.vue";
import AppButton from "../AppButton.vue";
import AppMap from "../AppMap.vue";

import {Icon} from "leaflet";
import {mapGetters} from "vuex";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconUrl: require("@/assets/images/map/placeholder.svg"),
});

export default {
  name: "AppFooter",
  components: {
    AppSubtitle,
    AppTitle,
    AppSocial,
    AppButton,
    AppMap,
  },

  data() {
    return {
      where: {
        text_ru: 'Где:',
        text_ua: 'Де:',
        text_pl: 'Gdzie:',
        text_en: 'Where:',
      },
      address: {
        text_ru: 'Львов, ул. Пекарская, 24',
        text_ua: 'Львів, вул. Пекарська, 24',
        text_pl: 'Lwów, ul. Pekarska, 24',
        text_en: 'Lviv, st. Pekarskaya, 24',
      },
      when: {
        text_ru: 'Когда:',
        text_ua: 'Коли:',
        text_pl: 'Gdy:',
        text_en: 'When:',
      },
      date: {
        text_ru: 'Пн-Вс: с 9:00 до 23:00',
        text_ua: 'Пн-Нд: з 9:00 до 23:00',
        text_pl: 'pon-niedz: od 9:00 do 23:00',
        text_en: 'Mon-Sun: from 9:00 to 23:00',
      },
      contact: {
        text_ru: 'Контакты:',
        text_ua: 'Контакти:',
        text_pl: 'Łączność:',
        text_en: 'Contacts:',
      },
      media: {
        text_ru: 'Медиа:',
        text_ua: 'Медіа:',
        text_pl: 'Głoska bezdźwięczna:',
        text_en: 'Media:',
      },
      text1: {
        text_ru: 'Вы можете сделать заказ прямо сейчас',
        text_ua: 'Ви можете зробити замовлення прямо зараз',
        text_pl: 'Możesz złożyć zamówienie już teraz',
        text_en: 'You can place an order right now',
      },
      delivery1: {
        text_ru: 'Заказать еду',
        text_ua: 'Замовити їжу',
        text_pl: 'Zamów jedzenie',
        text_en: 'Order food',
      }
    }
  },

  computed: {
    ...mapGetters('lang', ['selectedLang'])
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.myMap?.mapObject.ANY_LEAFLET_MAP_METHOD();
    });
  },
};
</script>

<style lang="scss">
.AppMainFooter {
  overflow: hidden;
  background: url("../../../../assets/images/main-footer__bg.jpg") center no-repeat;
  background-size: cover;
  padding-bottom: 30px;

  &__inner {
    @include flex(space-between, flex-start, row, wrap);
    min-height: 700px;
  }

  &__topbar {
    @include flex(space-between, flex-start, row, nowrap);
    width: 100%;

    @include media-d-m($screen-netbook) {
      @include flex(flex-start, flex-start, column-reverse, nowrap);
      width: 100%;
      padding-bottom: 100px;
    }
  }

  &__banner {
    @include flex(space-between, flex-start, column, nowrap);
    width: 100%;
    padding-top: 40px;

    @include media-d-m($screen-desktop-large) {
      margin-bottom: 0px;
    }
  }

  &__wrapper {
    @include flex(space-between, center, row, nowrap);
    width: 100%;

    &:not(:last-of-type) {
      margin-bottom: 70px;

      @include media-d-m($screen-netbook) {
        margin-bottom: 0;
      }
    }

    @include media-d-m($screen-netbook) {
      @include flex(flex-start, flex-start, column, nowrap);
    }
  }

  &__block {
    margin-right: 120px;
    min-width: 250px;

    @include media-d-m($screen-desktop-large) {
      margin-right: 60px;
    }

    @include media-d-m($screen-netbook) {
      margin-right: 0;
      bottom: 50px;
      margin-bottom: 50px;
    }
  }

  &__title {
    margin-bottom: 28px;

    @include media-d-m($screen-netbook) {
      margin-bottom: 20px;
    }
  }

  &__subtitle {
    margin-bottom: 9px;
    @include media-d-m($screen-netbook) {
      margin-bottom: 0;
    }
  }

  &__mainbar {
    @include flex(space-between, center, row, nowrap);
    width: 100%;
    position: relative;
    top: -40px;

    @include media-d-m($screen-desktop-large) {
      margin-bottom: 30px;
    }

    @include media-d-m($screen-netbook) {
      top: 0;
    }
  }

  &__map {
    margin-right: -200px;
    width: 100%;
    height: 500px;

    @include media-d-m($screen-netbook) {
      margin-right: -30px;
      margin-left: -30px;
      width: calc(100% + 60px);
      height: 500px;
    }

    @media screen and (max-width: 700px) {
      height: 400px;
    }

    @include media-d-m($screen-tablet-small) {
      height: 300px;
    }

    @include media-d-m($screen-iphone-6) {
      height: 210px;
    }
  }

  &__logo-group {
    margin-left: -204px;
    @include media-d-m($screen-desktop-large) {
      display: none;
    }
  }

  &__logo {
    max-width: 108px;
    @include media-d-m($screen-desktop-large) {
      display: none;
    }
  }

  &__button {
    margin-top: 16px;
  }

  &__copyright {
    @include flex(center, center, row, nowrap);
    @include text($h16, 300, $light-yellow, $f-montserrat);
    width: 100%;
  }
}
</style>
