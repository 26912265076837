import Vue from 'vue';
import App from './App.vue';
import store from '@/modules/core/store';

import { sync } from 'vuex-router-sync';
import { http, router } from './modules/http/router';

//Animation during scrolling
import AOS from 'aos';

//Scroll to anchor
import VueSmoothScroll from 'vue2-smooth-scroll';

//Mask for input
import VueDirectiveMask from '@vuejs-community/vue-directive-mask';

//Vue Leaflet map
import 'leaflet/dist/leaflet.css';

Vue.config.productionTip = false;

Vue.directive('scroll', {
  inserted: function(el, binding) {
    let f = function(evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f);
      }
    };
    window.addEventListener('scroll', f);
  },
});

// Sync router to store, as `store.state.route`.
sync(store, router);

// Http and Auth plugins
Vue.use(http);
Vue.use(VueSmoothScroll, {
  updateHistory: true,
});
Vue.use(VueDirectiveMask);

//styles
import './scss/style.scss';
import 'aos/dist/aos.css';

// eslint-disable-next-line no-unused-vars
const app = new Vue({
  el: '#app',
  store,
  AOS,
  router,
  render: (h) => h(App),
});

app.AOS = new AOS.init({
  // disable: 'phone',
  offset: 100,
  duration: 500,
  once: true,
});
