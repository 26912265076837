<template>
  <h4 class="subtitle" @click="$emit('click', $event)"><slot /></h4>
</template>

<script>
export default {
  name: 'AppSubtitle',
};
</script>

<style lang="scss">
.subtitle {
  line-height: 26.98px;
  @include text($h20, 300, $light-yellow, $f-montserrat);
  @include media-d-m($screen-netbook) {
    font-size: $h18;
  }

  &--medium {
    font-size: $h16;
    line-height: 21.1px;
  }

  &--small {
    font-size: $h15;
  }

  &--W400 {
    font-weight: 400;
  }
}
</style>
