<template>
  <a class="button" @click="$emit('click', $event)"><slot /></a>
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    href: String,
    SmoothScroll: Boolean,
  },
};
</script>

<style lang="scss">
.button {
  @include flex(center, center, row, nowrap);
  @include text($h15, 400, $yellow, $f-montserrat);
  cursor: pointer;
  min-width: 200px;
  height: 40px;
  border: 1px solid #f8c700;
  @include transition(box-shadow, color, background-color);
  padding: 0 36px;
  @include media-d-m($screen-netbook) {
    font-size: $h13;
    min-width: 200px;
    padding: 0 20px;
  }

  &--fill {
    background-color: $yellow;
    color: $body-color;
    &:hover {
      box-shadow: 0px 0px 30px rgba(248, 199, 0, 0.15);
    }
  }

  &--stroke {
    &:hover {
      background-color: $yellow;
      color: $body-color;
    }
  }

  &--slider {
    min-width: 45px;
    padding: 0 0;
    height: 45px;
    border: 1px solid $body-color;
    color: $body-color;
    font-size: $h20;
  }

  &--none {
    display: none;
  }

  &--footer {
    min-width: 45px;
    height: 45px;
    padding: 0 0px;
  }
}
</style>
