export default {
    types(state) {
        return state.types;
    },

    typesDelivery(state) {
        return state.typesDelivery;
    },

    typesList(state) {
        return Object.keys(state.types);
    },

    typesListDelivery(state) {
        console.log('keyssssss', Object.keys(state.typesDelivery));

        return Object.keys(state.typesDelivery);
    },

    // getTodoById: state => id => {
    //     return state.todos.find(todo => todo.id === id);
    // },

    getProductsByMenuId: (state) => (id) => {
        console.log('id ====>>', id);
        console.log(
            'FILTERED ====>>',
            state.products.filter((product) => product.menu_id === id)
        );
        return state.products.filter((product) => product.menu_id === id);
    },

    getProductCountById: (state) => (id) => {
        let count = 0;
        Object.values(state.types).forEach(menu => {
            menu.items.forEach(item => {
                if (item.item_id === id) {
                    console.log('PRODUCT::', item)
                    console.log('PRODUCT_COUNT::', item.count_selected)
                    console.log('STATE.TYPES::', state.types)
                    count = item.count_selected;
                }
            })
        });
        return count;
    },

    getActiveMenus(state) {
        let activeMenus = {};
        for (const key in state.types) {
            activeMenus =
                state.types[key].isActive === true
                ? { ...activeMenus, [key]: state.types[key] }
                : activeMenus;
        }

        console.log('activeMenus =>', activeMenus);
        return activeMenus;
    },

    getActiveMenusDelivery(state) {
        let activeMenus = {};
        for (const key in state.typesDelivery) {
            activeMenus =
                state.typesDelivery[key].isActive === true
                ? { ...activeMenus, [key]: state.typesDelivery[key] }
                : activeMenus;
        }

        console.log('activeMenus =>', activeMenus);
        return activeMenus;
    },

    getActiveMenusItems(state) {
        let activeMenusItems = [];

        for (const key in state.types) {
            activeMenusItems =
                state.typesDelivery[key].isActive === true
                ? (activeMenusItems = { ...state.typesDelivery[key].items })
                : activeMenusItems;
        }

        console.log('activeMenusItems =>', activeMenusItems);
        return activeMenusItems;
    },

    // typesData(state) {
    //     return Object.values(state.types);
    // }
}
