import state from "./state";


export default {
    // updateFooter(state, footer) {
    //     state.footer = { ...state.footer, ...footer };
    // },

    loadState(state, loaded) {
        state = { ...state, ...loaded }
    },

    selectlang(state, lang) {
        state.selected = lang;
    }
}
