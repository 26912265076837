export default {
    getData(state) {
        return state.data;
    },
    getInfo(state) {
        let info = {
            name: state.data.name,
            phone: state.data.phone,
            email: state.data.email,
            address: state.data.address,
            comment: state.data.comment,
        };
        if (!state.data.pickup) {
            info.pickup = false;
            if (state.data.later) {
                info.delivery_date = {date: state.data.date, time: state.data.time};
                info.delivery_asap = 'later';
            } else {
                info.delivery_asap = 'Just now';
            }
        } else {
            info.pickup = state.data.pickup;
        }
        return info;
    },
    getPageSteps(state) {
        console.log(state.page.steps);
        return state.page.steps;
    },
    getPageActiveStep(state) {
        console.log(state.page.active);
        return state.page.active;
    },
    getPageDisabledSteps(state) {
        console.log(state.page.done);

        return state.page.done;
    },
    // getPageOrderedSteps(state) {
    //   const [sorted, steps] = [[], state.page.steps];
    //   for (const key in steps) {
    //     sorted[steps[key].sort] = key;
    //   }
    //   return sorted;
    // },
    // getPageActiveStepKey: (state) => (key) => {
    //   const obj = {
    //     active: state.page.stepper.steps.find(item => item[kry]),
    //     done: state.page.stepper.steps[key].done,
    //   };
    //   return obj;
    // },
    getPage(state) {
        return state.page;
    },
}
