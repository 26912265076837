import menu from '@/modules/app/modules/menu/store';
import lang from '@/modules/app/modules/lang/store';
import * as constants from '@/constants';

// Sync with local storage.
if (localStorage.getItem(constants.STORAGE_KEY)) {
  const syncedState = JSON.parse(localStorage.getItem(constants.STORAGE_KEY));
  // cart.state = { ...cart.state, ...syncedState.cart };
  // menu.state = { ...menu.state, ...syncedState.menu };
}

// LocalStorage plugin.
const localStoragePlugin = (store) => {
  store.subscribe((mutation, state) => {
    const syncedData = {
      // auth: state.auth,
      // cart: state.cart,
      // menu: state.menu,
    };

    localStorage.setItem(constants.STORAGE_KEY, JSON.stringify(syncedData));

    if (mutation.type === 'clear') {
      localStorage.removeItem(constants.STORAGE_KEY);
    }
  });
};

export { localStoragePlugin };
