import state from "./state";

import defaults from './state'


export default {
    initPageText(state, { texts }) {
        state.texts = texts;
    }
}
