import state                from './state';
import actions              from './actions';
import mutations            from './mutations';
import getters              from './getters';


const store = {
    state     : { ...state },
    mutations: {
        ...mutations,
    },
    actions  : {
        ...actions,
    },
    getters  : {
        ...getters
    },
};

export default store;
