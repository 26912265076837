import { API_BASE_URL } from "@/constants";

export default {
    async initBlog({ commit, getters }) {
        console.log('GETTERS_FROM_INIT_BLOG_ACTION', getters)
        const response = await axios.get(API_BASE_URL + 'posts');

        console.log('BLOG_INIT_RESPONSE', response.data.resource.data);

        commit('initBlog', { posts: response.data.resource.data })
    }

};
