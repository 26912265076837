// import { localStoragePlugin } from "@/modules/core/store/plugins/localStorage";

export default {
  // apiInitDispatch: {
  //   root: true,
  //   handler({ commit, dispatch }) {
  //   },
  // },
  //
  // updateTitle: {
  //   root: true,
  //   handler({ commit }, title) {
  //     commit('updateTitle', title);
  //   },
  // },
  // updateTitle({ commit }, title) {
  //   commit('updateTitle', title);
  // },

    loadState({ commit }, state) {
        commit('loadState', state);
    },

    changeLang({ commit }, lang) {
        commit('selectlang', lang);
    }

};
