export default {
    data: {
        name: '',
        phone: '',
        email: '',
        address: '',
        comment: '',
        cartNoteItems: '',
        cartNotePies: '',
        time: '',
        date: '',
        pickup: false,
        delivery_asap: false,
        later: false,
        agree: '',
        allBool: false,
        chooseAllBool: false,
    },
    page: {
        active: 'isTill',
        done: ['isOrdering'],
        steps: {
            isOrdering: {
                title_ru: 'Оформление заказа',
                title_ua: 'Оформлення замовлення',
                title_en: 'Checkout',
                title_pl: 'Sprawdzić',
                sort: 0,
            },
            isTill: {
                title_ru: 'Касса',
                title_ua: 'Каса',
                title_en: 'Cashbox',
                title_pl: 'Skarbona',
                component: 'AppOrderingCashier',
                sort: 1,
            },
            isAudit: {
                title_ru: 'Проверка заказа',
                title_ua: 'Перевірка замовлення',
                title_en: 'Checking your order',
                title_pl: 'Sprawdzanie zamówienia',
                component: 'AppOrderingAudit',
                sort: 2,
            },
            isPay: {
                title_ru: 'Оплата',
                title_ua: 'Оплата',
                title_en: 'Payment',
                title_pl: 'Zapłata',
                component: 'AppOrderingPay',
                sort: 3
            },
            isDone: {
                title_ru: 'Готово',
                title_ua: 'Готово',
                title_en: 'Done',
                title_pl: 'Gotowy',
                component: 'AppOrderingDone',
                sort: 4},
        },
    },
};
