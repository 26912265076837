import state from "./state";

import defaults from './state'


export default {
    updateName(state, { name, value }) {
        state.data[name] = value;
    },
    updateData(state, { key, value }) {
        console.log(key + ': ' + value);
        state.data[key] = value;
    },
    updatePageComponent(state, { name }) {
        state.page.activeComponent = name;
    },
    updateActiveStep(state, { key }) {
        let arrayDone = state.page.done;

        if (arrayDone.indexOf(key) !== -1) {
            const keyIndex = arrayDone.indexOf(key);
            arrayDone.splice(keyIndex, 1);
        } else {
            arrayDone.push(state.page.active);
        }

        state.page.active = key;
    },
    clearOrderPageState(state) {
        state.page.done = [];
        state.page.active = 'isTill';
        state.data = {
            name         : '',
            phone        : '',
            email        : '',
            address      : '',
            comment      : '',
            cartNoteItems: '',
            cartNotePies : '',
            time         : '',
            date         : '',
            pickup       : false,
            delivery_asap: false,
            later        : false,
            agree        : '',
            allBool      : false,
            chooseAllBool: false,
        }


    },
    resetState(state) {
        Object.assign(state, getDefaultState())
    },

    resetStateItem(state) {
        Object.assign(state, getDefaultStateItem())
    },

    resetStatePie(state) {
        Object.assign(state, getDefaultStatePie())
    },
}
