<template>
  <div>
    <AppHeader />
    <transition-group name="fade" mode="out-in">
      <router-view key="id" />
    </transition-group>

    <component
      :is="this.footerComponent || 'AppMainFooter'"
      :class="this.footerClassName || ''"
    />
  </div>
</template>

<script>
import AppHeader     from '@/modules/app/components/AppHeader';
import AppFooter     from '@/modules/app/components/TheFooter/AppFooter';
import AppMainFooter from '@/modules/app/components/TheFooter/AppMainFooter';

export default {
  name: 'DefaultLayout',
  props: { data: Object },
  components: { AppHeader, AppFooter, AppMainFooter },
  data() {
    return {};
  },
  methods: {},
  computed: {
    // ...mapGetters([
    //     'getFooter'
    // ]),
    footerComponent() {
      return this.$store.state?.footer.componentName;
    },
    footerClassName() {
      return this.$store.state?.footer.data.class;
    },

    // footerData() {
    //   return this.$store.state.layoutData.footer.data
    // }
  },
};
</script>

<style lang="scss">
.box {
  transition: 1.5s all cubic-bezier(0.39, 0.575, 0.565, 1);
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
