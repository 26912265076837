import { API_BASE_URL } from '@/constants';

export default {
    changeData({ commit }, { key, value }) {
        commit('updateData', { key: key, value: value });
    },
    changeActiveStep({ commit }, { key }) {
        commit('updateActiveStep', { key });
    },
    applyOrder({ state, commit }) {
        console.log('SUBMIT_ORDER::STATE', state);
    },
    changeActiveKey({ commit }, { oldKey, newKey }) {
        commit('updateActiveKey', { oldKey: oldKey, newKey: newKey });
    },
    changePageComponent({ commit }, { name }) {
        commit('updatePageComponent', name);
    },
    resetState({ commit }) {
        commit('resetState');
    },
    async createOrder({ commit, rootState, state, dispatch }) {
        const order = {
            billingData: state.data,
        }
        order.billingData.delivery_date = order.billingData.later === true ?
                                          new Date(Date.parse(order.billingData.date + ' ' + order.billingData.time)).toISOString()
                                                                           : '';

        if (Object.keys(rootState.cart.data.items).length > 0) {
            order.items = rootState.cart.data.items;
        }
        if (Object.keys(rootState.cart.data.pies).length > 0) {
            order.pies = rootState.cart.data.pies;
        }

        const productsApiResponse = await axios.post(
            API_BASE_URL + 'order/create?XDEBUG_SESSION_START=PHPSTORM',
            order
        );

        commit('clearOrderPageState');
    }
};
