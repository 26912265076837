import state from "./state";

import defaults from './state'


export default {
    update(state, { key, data }) {
        state[key] = { ...state[key], ...data };
    },
    clear(state) {
        state = { ...state, ...defaults };
    },
    selectType(state, { key, value }) {
        console.log('SELCASDASD, -----------------', key);
        console.log('SELCASDASD, -----------------', value  );
        for (const [key, value] of Object.entries(state.types)) {
            state.types[key].isActive = false
        }
        state.types[key] = { ...state.types[key], ...value };
    },

    selectTypeAllDisable(state, { key, value }) {
        state.keys.forEach((elem) => {
            state.typesDelivery[elem].isActive = false;
        });
        state.typesDelivery[key] = { ...state.typesDelivery[key], ...value };
    },

    updateProducts(state, { data }) {
        state.products = data;
    },

    updateKeys(state, { data }) {
        state.keys = data;
    },

    updateItemAmount(state , { menuIndex, itemIndex, item }) {
        console.log('MUTATION::', menuIndex, itemIndex, item)
        state.types[+menuIndex].items[itemIndex] = item;
    }
}
