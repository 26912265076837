export default {
    getCartItems(state) {
        return state.data.items;
    },

    getCartPies(state) {
        return state.data.pies;
    },

    getCartAll(state) {
        return Object.assign({}, state.data.items, state.data.pies)
    },

    getCartItemsTotalAmount(state) {
        return state.data.totalItemsAmount;
    },

    getCartPiesTotalAmount(state) {
        return state.data.totalPiesAmount;
    },

    getCartTotalPrice(state) {
        return state.data.totalItemsPrice + state.data.totalPiesPrice;
    },

    getCartIsDropped(state) {
        return state.isDropped;
    },

    getAmountItem: (state) => (id) => {
        return !!state.data.items[id]?.amount ? state.data.items[id].amount : 0;
    },
}
