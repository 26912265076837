var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"AppFooter",class:{
    noBacground: this['footer'].data.imageUrl === 'none',
    dNone: !this['footer'].data.display,
  },style:({
    'background-image':
      'url(' +
      require('@/assets/images/footer/' +
        this['footer'].data.imageUrl +
        '.png') +
      ')',
  })},[_c('div',{staticClass:"AppFooter__inner container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(this['footer'].data.showDelivery),expression:"this['footer'].data.showDelivery"}],staticClass:"AppFooter__delivery"},[_c('app-title',{staticClass:"AppFooter__title title--medium"},[_vm._v(" "+_vm._s(this["footer"].data["title_" + _vm.selectedLang])+" ")]),_c('router-link',{attrs:{"to":"/delivery"}},[_c('app-button',{staticClass:"button--fill"},[_vm._v(" "+_vm._s(this["footer"].data["buttonTitle_" + _vm.selectedLang])+" ")])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(this['footer'].data.showNav),expression:"this['footer'].data.showNav"}],staticClass:"AppFooter__nav",class:{ 'with-out-allergy': !this['footer'].data.showAllergy }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(this['footer'].data.showAllergy),expression:"this['footer'].data.showAllergy"}],staticClass:"AppFooter__allergy"},[_c('a',{attrs:{"href":""}},[_vm._v(_vm._s(_vm.allergen['text_' + _vm.selectedLang]))])]),_c('div',{staticClass:"AppFooter__up"},[_vm._v(" Наверх "),_c('a',{directives:[{name:"smooth-scroll",rawName:"v-smooth-scroll"}],attrs:{"href":'#' + this['footer'].data.href}},[_c('app-button',{staticClass:"AppFooter__button button--footer button--stroke",attrs:{"href":'#' + this['footer'].data.href}},[_c('icon-arrow',{staticClass:"AppFooter__arrow iconArrow--up iconArrow--yellow"})],1)],1)])]),_c('div',{staticClass:"AppFooter__mainbar"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('AppSocial',{staticClass:"AppFooter__social"})],1),_c('div',{staticClass:"AppFooter__copyright"},[_vm._v("© Powered by I2Unix LLC")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"AppFooter__contact"},[_c('p',[_c('a',{attrs:{"href":"tel:+38 (050) 372 27 30"}},[_vm._v("+38 (050) 372 27 30")])]),_c('p',[_c('a',{attrs:{"href":"mailto:mrs.greenwich@gmail.com"}},[_vm._v("mrs.greenwich@gmail.com")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"AppFooter__logo-group"},[_c('img',{attrs:{"src":require("@/assets/images/footer__logo__group.svg"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"AppFooter__logo"},[_c('img',{attrs:{"src":require("@/assets/images/logo__white.png"),"alt":"","height":"30px"}})])}]

export { render, staticRenderFns }