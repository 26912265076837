export default {
    footer: function(state) {
        return state.footer;
    },
    header: function(state) {
        return state.header;
    },

    // getCheesecake(state) {
    //     return state.cheesecake;
    // },

    // getPies(state) {
    //     return state.pies;
    // },

    getIsDropMenuActive(state) {
        return state.isDropMenuActive;
    },
}
