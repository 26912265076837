import { API_BASE_URL } from "@/constants";

export default {
    // loadState({ commit }, state) {
    //     commit('loadState', state);
    // },

    // changeLang({ commit }, lang) {
    //     console.log('LANG', lang)
    //     commit('selectlang', lang);
    // }

    async initPies({ commit, getters }) {
        const response = await axios.get(API_BASE_URL + 'pies');

        console.log('PIE_RESPONSE::::', response)

        commit('initPies', { pies: response.data.resource.data })
    },

    updatePieAmount({ commit, state }, { id, amount }) {
        id = +id;
        let [pieIndex, pie] = [];

        Object.keys(state.pies).forEach(localPieIndex => {
            if (state.pies[localPieIndex].pie_id === id) {
                [pieIndex, pie] = [localPieIndex, state.pies[localPieIndex]];
            }
        })

        if (typeof pieIndex !== undefined) {
            pie.selected_count = amount;
            commit('updatePieAmount', { pieIndex, pie })
        }
    }
};
