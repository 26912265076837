<template>
  <h3 class="title" @click="$emit('click', $event)"><slot /></h3>
</template>

<script>
export default {
  name: 'AppTitle',
};
</script>

<style lang="scss">
.title {
  line-height: 69.48px;
  @include text($h68, 400, $light-yellow, $merriweather);
  @include media-d-m($screen-netbook) {
    font-size: $h31;
  }
  @include media-d-m($screen-netbook) {
    line-height: 31.68px;
  }

  &--yellow {
    color: $yellow;
  }

  &--medium {
    font-size: $h49;
    line-height: 50px;
    @include media-d-m($screen-netbook) {
      font-size: $h31;
    }
    @include media-d-m($screen-netbook) {
      line-height: 31.68px;
    }
  }
  &--small {
    font-size: $h26;
    line-height: 29px;
    @include media-d-m($screen-netbook) {
      font-size: $h22;
    }
  }

  &--montserrat {
    font-family: $f-montserrat;
  }

  &--W500 {
    font-weight: 500;
  }

  &--W300 {
    font-weight: 300;
  }
}
</style>
