// import Vue from 'vue';
// import Vuex from 'vuex';
//
// import state from '@/modules/core/store/state';
// import actions from '@/modules/core/store/actions';
// import mutations from '@/modules/core/store/mutations';
//
// /** Project modules import here  **/
// import products  from '@/modules/app/modules/products/store';
// import cart from '@/modules/app/modules/cart/store'
//
// import { localStoragePlugin } from '@/modules/core/store/plugins/localStorage';
//
// Vue.use(Vuex);
//
// const store = new Vuex.Store({
//   state: { core: state },
//   modules: {
//     products, cart
//   },
//   mutations: {
//     ...mutations,
//   },
//   actions: {
//     ...actions,
//   },
//
//   plugins: [localStoragePlugin],
// });
//
// export default store;

import Vuex from 'vuex';
import Vue  from 'vue';

import state     from './state';
import actions   from './actions';
import mutations from './mutations';
import getters   from './getters';

import menu from '@/modules/app/modules/menu/store';
import pies from '@/modules/app/modules/pies/store';
import ordering from '@/modules/app/modules/ordering/store';
import cart from '@/modules/app/modules/cart/store';
import blog from '@/modules/app/modules/blog/store';
import lang from '@/modules/app/modules/lang/store';
import text from '@/modules/app/modules/texts/store';


import {
    localStoragePlugin,
} from './plugins/localStorage';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);


console.log('CORE_STATE_INIT', state);
const store = new Vuex.Store({
    state     : { core: state },
    modules   : { menu, pies, ordering, cart, blog, lang, text },

    mutations: {
        ...mutations,
    },
    actions  : {
        ...actions,
    },
    getters  : {
        ...getters
    },
    plugins  : [ createPersistedState() ],
});

export default store;
